<template>
  <div style="width: 100%; height: 100%">
    <div class="d-flex justify-center align-center" style="width: 100%">
      <v-btn icon @click="$refs.calendar.prev()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <div style="width: 300px" class="text-center">
        <div>{{ calendarTitle }}</div>
      </div>
      <v-btn icon @click="$refs.calendar.next()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
    <div style="width: 95vw; height: 70vh">
      <v-calendar
        ref="calendar"
        v-model="focus"
        type="month"
        event-start="date"
        event-end="date"
        color="primary"
        :event-ripple="false"
        @change="handleCalendarChange"
        locale="it"
        :loading="loadingClosures"
        @click:day="handleDayClick"
      >
        <template v-slot:day-label="{ date }">
          <div class="d-flex justify-center">
            <div
              class="day-label"
            >
              <span class="font-weight-bold">{{dateToString("dd/mm/yyyy", new Date(date))}}</span> 
              - Totale: 
              <span class="font-weight-bold">{{getTotalOfDay(date).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</span>
            </div>
          </div>
        </template>
      </v-calendar>
    </div>
    <div @click="handleSummClick" class="pt-2">
      <div class="d-flex justify-center">
        <div class="day-label">
          Totale mensile: 
          <span class="font-weight-bold">{{getTotalOfSumm().toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</span>
        </div>
      </div>
    </div>

    <v-dialog
      v-model="closureDetailDialog"
      transition="scroll-y-transition"
    >
      <CalculatorCard
        :object="!!showingClosure ? showingClosure.total : undefined"
        :title="closureDetailDialogTitle"
        :can-view-ghost="canViewGhost"
      >
        <template v-slot:footer>
          <v-divider></v-divider>
          <div class="pa-1 d-flex">
            <v-spacer></v-spacer>
            <v-btn 
              text 
              color="error" 
              class="mr-auto"
              @click="closeClosureDetailDialog"
            >Chiudi</v-btn>
          </div>
        </template>
      </CalculatorCard>
    </v-dialog>
  </div>
</template>

<style scoped>
  .day-label {
    background-color: #8a77e2;
    color: aliceblue;
    width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
  }
</style>

<script>
import StandardDialog from "@/components/common/StandardDialog.vue";
import closuresService from "@/services/closures/closures.service.js"
import colorContrast from "@/mixins/common/colorContrast";
import dateUtils from '@/mixins/common/dateUtils'
import CalculatorCard from './CalculatorCard.vue'

export default {
  name: "MontlhyClosure",
  components: {
    StandardDialog,
    CalculatorCard
  },
  mixins: [dateUtils, colorContrast],
  data: function() {
    let props = this.$router.resolve({
      name: "Payments",
    });

    return {
      dialog: true,
      type: "month",
      routeFather: props.href,
      focus: undefined,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      calendarTitle: undefined,
      colorMapping: {},
      loadingClosures: false,
      totalClosures: {},
      showingClosure: undefined,
      closureDetailDialog: false,
      closureDetailDialogTitle: undefined,
      summaryClosure: undefined,
    };
  },
  props: {
    canViewGhost: {
      type: Boolean,
      default: false
    },
  },
  mounted: function() {
    this.$refs.calendar.checkChange();

  },
  methods: {
    setToday() {
      this.focus = "";
    },
    sumCurrencyAmounts(...amounts) {
      if(!amounts) {
        return 0
      } else if(amounts.length == 0) {
        return 0
      } else {
        let accumulator = 0
        for(let i = 0; i < amounts.length; i += 1) {
          if(!!amounts[i]) {
            accumulator = Number(Number(Number(Number(accumulator).toFixed(2)) + Number(Number(amounts[i]).toFixed(2))).toFixed(2))
          }
        }
        return accumulator
      }
    },
    getTotalOfDay(date) {
      date = new Date(date)
      date.setHours(0)
      date.setTime(date.getTime() + 60000)

      if(!!this.totalClosures[this.getLabel(date)] && !!this.totalClosures[this.getLabel(date)].total) {
        const closure = {...this.totalClosures[this.getLabel(date)]}
        let total = 0

        closure.cashDesks.forEach(cashDesk => {
          total = this.sumCurrencyAmounts(
            total,
            cashDesk.billsCashPaid, 
            cashDesk.customerCardLoadCashPaid, 
            cashDesk.paymentCreditCashPaid,
            cashDesk.promoCashPaid,
            cashDesk.invoicesCashPaid,
            cashDesk.billsCardPaid, 
            cashDesk.customerCardLoadCardPaid, 
            cashDesk.paymentCreditCardPaid,
            cashDesk.promoCardPaid,
            cashDesk.invoicesCardPaid,
            cashDesk.billsCheckPaid, 
            cashDesk.customerCardLoadCheckPaid, 
            cashDesk.paymentCreditCheckPaid,
            cashDesk.promoCheckPaid,
            cashDesk.invoicesCheckPaid,
            cashDesk.billsOtherPaid,
            cashDesk.customerCardLoadOtherPaid,
            cashDesk.paymentCreditOtherPaid,
            cashDesk.promoOtherPaid,
            (-1 * cashDesk.paymentDebitTotal),
            (-1 * cashDesk.operatorPaymentDebitTotal)
          )
        })
        

        return total
      } else {
        return 0
      }
    },
    getLabel(date){
      let newTime = new Date(date)
      return newTime.toDateString()
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    handleCalendarChange({ start, end }) {
      this.calendarTitle = this.$refs.calendar.title;

      this.fetchClosuresMonthly(new Date(start.date), new Date(end.date));
    },
    fetchClosuresMonthly(start, end) {
      let startDate = new Date(start), endDate = new Date(end)
      startDate.setHours(0)
      startDate.setMinutes(0)
      startDate.setSeconds(0)
      startDate.setMilliseconds(0)
      startDate.setTime(startDate.getTime() + 60000)

      endDate.setHours(23)
      endDate.setMinutes(59)
      endDate.setSeconds(59)
      endDate.setMilliseconds(999)
      endDate.setTime(endDate.getTime() + 60000)

      this.loadingClosures = true
      closuresService.dailyClosures(startDate, endDate).then((results) => {
        this.totalClosures = results.results
        this.summaryClosure = results.totalResult
        this.loadingClosures = false
      })
    },
    handleDayClick({ date }) {
      let timeKey = new Date(date).getTime()
      timeKey = new Date(timeKey)
      timeKey.setHours(0)
      timeKey.setMilliseconds(0)
      timeKey.setTime(timeKey.getTime() + 60000)

      this.showingClosure = !!this.totalClosures[this.getLabel(timeKey)] ? {...this.totalClosures[this.getLabel(timeKey)]} : {}
      this.closureDetailDialogTitle = 'Corrispettivi del giorno ' + this.dateToString('dd/mm/yyyy', new Date(date))
      this.closureDetailDialog = true
    },
    handleSummClick(){
      this.showingClosure = { total: this.summaryClosure }
      this.closureDetailDialogTitle = 'Corrispettivi del mese'
      this.closureDetailDialog = true
    },
    getTotalOfSumm() {
      let total = 0
      if(!!this.summaryClosure) {
        total = this.sumCurrencyAmounts(
          total,
          this.summaryClosure.billsCashPaid, 
          this.summaryClosure.customerCardLoadCashPaid, 
          this.summaryClosure.paymentCreditCashPaid,
          this.summaryClosure.promoCashPaid,
          this.summaryClosure.invoicesCashPaid,
          this.summaryClosure.billsCardPaid, 
          this.summaryClosure.customerCardLoadCardPaid, 
          this.summaryClosure.paymentCreditCardPaid,
          this.summaryClosure.promoCardPaid,
          this.summaryClosure.invoicesCardPaid,
          this.summaryClosure.billsCheckPaid, 
          this.summaryClosure.customerCardLoadCheckPaid, 
          this.summaryClosure.paymentCreditCheckPaid,
          this.summaryClosure.promoCheckPaid,
          this.summaryClosure.invoicesCheckPaid,
          this.summaryClosure.billsOtherPaid,
          this.summaryClosure.customerCardLoadOtherPaid,
          this.summaryClosure.paymentCreditOtherPaid,
          this.summaryClosure.promoOtherPaid,
          (-1 * this.summaryClosure.paymentDebitTotal),
          (-1 * this.summaryClosure.operatorPaymentDebitTotal)
        )        
      }

      return total
    },
    closeClosureDetailDialog() {
      this.showingClosure = undefined
      this.closureDetailDialog = false
    }
  },
  computed: {
  },
  watch: {}
};
</script>

<style>
</style>