<template>
  <v-card 
    :max-width="maxWidth" 
    :id="'cash_' + object.cashDeskId"
    style="overflow: auto;"
  >
    <v-card-title>
      <div>
        <div>{{title}}</div>
        <div v-if="!!parseInt(cashFund)" class="font-weight-light text-caption">
          Fondo cassa escluso: {{cashFund}}
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        icon
        @click="print"
        color='default'
      >
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <div class="d-flex">
      <v-card-subtitle class="mr-3">
        <div>
          <table class="table-of-value">
            <thead>
              <tr>
                <th class="font-weight-bold table-header right-border"></th>
                <th class="font-weight-bold table-header right-border">Incassi in store</th>
                <th class="font-weight-bold table-header right-border">Di cui fatture in store</th>
                <th class="font-weight-bold table-header right-border">Di cui sospesi incassati</th>
                <th 
                  class="font-weight-bold table-header right-border" 
                  v-if="canViewGhost"
                  :style="{color: ghostColor}"
                >Concordato</th>
                <th
                  class="font-weight-bold table-header right-border" 
                  v-if="canViewGhost"
                  :style="{color: ghostColor}"
                >Di cui sospesi incassati</th>
                <th class="font-weight-bold table-header">Totale nel dettaglio</th>
              </tr>
            </thead>
            <tbody style="color: rgba(0, 0, 0, 0.6);">
              <tr>
                <td class="font-weight-bold descriptor right-border">Contanti</td>
                <td class="text-overline value right-border">{{cash.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value right-border"> {{cashInvoices.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value right-border"> {{cashPaymentCredit.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value right-border" :style="{color: ghostColor}" v-if="canViewGhost"> {{cashGhost.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value right-border" :style="{color: ghostColor}" v-if="canViewGhost"> {{cashGhostPaymentCredit.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value"> {{cashDetail.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
              </tr>
              <tr>
                <td class="font-weight-bold descriptor right-border">Elettronico</td>
                <td class="text-overline value right-border"> {{card.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value right-border"> {{cardInvoices.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value right-border"> {{cardPaymentCredit.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value right-border" :style="{color: ghostColor}" v-if="canViewGhost"></td>
                <td class="text-overline value right-border" :style="{color: ghostColor}" v-if="canViewGhost"></td>
                <td class="text-overline value"><span class="yellow"> {{card.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</span></td>
              </tr>
              <tr>
                <td class="font-weight-bold descriptor right-border">Assegni</td>
                <td class="text-overline value right-border"> {{check.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value right-border"> {{checkInvoices.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value right-border"> {{checkPaymentCredit.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value right-border" :style="{color: ghostColor}" v-if="canViewGhost"></td>
                <td class="text-overline value right-border" :style="{color: ghostColor}" v-if="canViewGhost"></td>
                <td class="text-overline value"> {{check.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
              </tr>
              <tr>
                <td class="font-weight-bold descriptor right-border">Ticket e altro</td>
                <td class="text-overline value right-border"> {{otherPaid.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value right-border"> {{otherInvoices.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value right-border"> {{otherPaymentCredit.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value right-border" :style="{color: ghostColor}" v-if="canViewGhost"></td>
                <td class="text-overline value right-border" :style="{color: ghostColor}" v-if="canViewGhost"></td>
                <td class="text-overline value"> {{otherPaid.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
              </tr>
              <tr>
                <td class="font-weight-bold descriptor top-border">Totale parziale incasso</td>
                <td class="text-overline value top-border"> {{partialTotalInStore.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value top-border"> {{partialTotalInvoices.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value top-border"> {{partialTotalPaymentCredit.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value top-border" :style="{color: ghostColor}" v-if="canViewGhost"> {{partialTotalGhost.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value top-border" :style="{color: ghostColor}" v-if="canViewGhost"> {{partialTotalPaymentCreditGhost.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value top-border"> {{partialTotal.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
              </tr>
              <tr>
                <td class="font-weight-bold table-header right-border top-border"></td>
                <td class="font-weight-bold table-header right-border top-border">Spese in store</td>
                <td 
                  class="font-weight-bold table-header right-border top-border" 
                  v-if="canViewGhost"
                  :style="{color: ghostColor}"
                >Spese concordato</td>
                <td class="font-weight-bold table-header top-border right-border">Totale nel dettaglio</td>
              </tr>
              <tr>
                <td class="font-weight-bold descriptor right-border">Pagamenti</td>
                <td class="text-overline value right-border"> {{operatorPaymentDebitTotal.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value right-border" :style="{color: ghostColor}" v-if="canViewGhost"> {{operatorPaymentDebitTotalGhost.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value right-border"> {{operatorPaymentDebitDetail.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
              </tr>
              <tr>
                <td class="font-weight-bold descriptor right-border">Storni per contanti</td>
                <td class="text-overline value right-border"> {{paymentDebitTotal.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value right-border" :style="{color: ghostColor}" v-if="canViewGhost"></td>
                <td class="text-overline value right-border"> {{paymentDebitTotal.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
              </tr>
              <tr>
                <td class="font-weight-bold descriptor top-border">Totale parziale costo</td>
                <td class="text-overline value top-border"> {{partialPaymentDebitTotal.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value top-border" :style="{color: ghostColor}" v-if="canViewGhost"> {{partialPaymentDebitTotalGhost.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value top-border"> {{partialPaymentDebitTotalPlus.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
              </tr>
              <tr>
                <td class="font-weight-bold descriptor top-border">Versamento per contanti</td>
                <td class="text-overline value top-border"> {{bankCash.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value top-border" :style="{color: ghostColor}" v-if="canViewGhost">{{bankCashGhost.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value top-border"><span class="yellow"> {{bankTotal.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</span></td>
              </tr>
              <tr>
                <td class="font-weight-bold descriptor top-border">Totale generale</td>
                <td class="text-overline value top-border"> {{generalTotal.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value top-border" :style="{color: ghostColor}" v-if="canViewGhost">{{bankCashGhost.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
                <td class="text-overline value top-border"> {{generalTotalPlus.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-card-subtitle>
    </div>
    <slot name="footer">
    </slot>
  </v-card>
</template>

<script>
import { cloneDeep } from 'lodash'
import html2canvas from '@/assets/js/html2canvas.min.js'

export default {
  name: "CalculatorCard",
  data: function() {
    return {
      showCalculator: false,
      includeArray: [],
      localClosureCalculations: [],
      editingCalculationIndex: undefined,
      newCalculation: undefined,
      calculationText: 'Calcolo'
    }
  },
  props: {
    object: {
      type: Object,
      default: () => ({
        cashDeskName: undefined
      })
    },
    canViewGhost: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: undefined
    },
    maxWidth: {
      type: String,
      default: '900px'
    },
    cashFund: {
      type: String,
      default: undefined
    }
  },
  methods: {
    keyToText(key) {
      const keyToTextMapping = {
        cashDeskCashFund: 'Fondo Cassa',
        billsCashPaid: 'Contante in salone',
        billsCardPaid: 'Elettronico in salone',
        billsCheckPaid: 'Assegni in salone',
        billsOtherPaid: 'Ticket in salone',
        billsNotPaid: 'Non pagato in salone',
        billsGhostPaid: 'Concordato in salone',
        billsDiscounts: 'Sconti in salone',
        billsInvoices: 'Fatture in salone',
        customerCardLoadCashPaid: 'Contanti ricariche tessere',
        customerCardLoadCardPaid: 'Elettronico ricariche tessere',
        customerCardLoadCheckPaid: 'Assegni ricariche tessere',
        customerCardLoadOtherPaid: 'Ticket ricariche tessere',
        customerCardLoadGhostPaid: 'Concordato ricariche tessere',
        paymentCreditCashPaid: 'Contanti da pagamento sospesi',
        paymentCreditCardPaid: 'Elettronico da pagamento sospesi',
        paymentCreditCheckPaid: 'Assegni da pagamento sospesi',
        paymentCreditOtherPaid: 'Ticket da pagamento sospesi',
        paymentCreditGhostPaid: 'Concordato da pagamento sospesi',
        paymentDebitTotal: 'Totale storni',
        operatorPaymentDebitTotal: 'Totale spese per contanti',
        operatorPaymentDebitTotalGhost: 'Totale spese per contanti concordato',
        promoCashPaid: 'Promo pagate in contanti',
        promoCardPaid: 'Promo pagate con carta',
        promoCheckPaid: 'Promo pagate con assegno',
        promoOtherPaid: 'Promo pagate con altro',
        promoNotPaid: 'Promo non pagate',
        promoGhostPaid: 'Promo concordato',
        invoicesCashPaid: 'Fatture in contanti',
        invoicesCardPaid: 'Fatture in elettronico',
        invoicesCheckPaid: 'Fatture in assegno',
        invoicesOtherPaid: 'Fatture in altro',
        invoicesGhostPaid: 'Fatture concordato',
      }

      return keyToTextMapping[key]
    },
    sumCurrencyAmounts(...amounts) {
      if(!amounts) {
        return 0
      } else if(amounts.length == 0) {
        return 0
      } else {
        let accumulator = 0
        for(let i = 0; i < amounts.length; i += 1) {
          if(!!amounts[i]) {
            accumulator = Number(Number(Number(Number(accumulator).toFixed(2)) + Number(Number(amounts[i]).toFixed(2))).toFixed(2))
          }
        }
        return accumulator
      }
    },
    subtractCurrencyAmounts(...amounts) {
      if(!amounts) {
        return 0
      } else if(amounts.length == 0) {
        return 0
      } else {
        let accumulator = 0
        for(let i = 0; i < amounts.length; i += 1) {
          if(!!amounts[i]) {
            accumulator = Number(Number(Number(Number(accumulator).toFixed(2)) - Number(Number(amounts[i]).toFixed(2))).toFixed(2))
          }
        }
        return accumulator
      }
    },
    getCalculationValue(calculation) {
      if(!!calculation && !!calculation.values && this.objectPresent) {
        let sums = [], subs = []
        for(let i = 0; i < calculation.values.length; i+=1) {
          if(calculation.values[i].type == 'sum') {
            sums.push(this.object[calculation.values[i].field])
          } else {
            subs.push(this.object[calculation.values[i].field])
          }
        }
        
        let total = this.sumCurrencyAmounts(...sums)
        if(subs.length > 0) total = this.subtractCurrencyAmounts(totale, ...subs)
        return total
      }
    },
    handleTextChange(newText) {
      if(this.editingCalculationIndex !== undefined) {
        this.localClosureCalculations[this.editingCalculationIndex].text = newText
      } else {
        if(!this.newCalculation) {
          this.newCalculation = {
            text: newText,
            values: []
          }
        } else {
          this.newCalculation.text = newText
        }
      }
    },
    handleCheckboxInput(field, newValue) {
      if(this.editingCalculationIndex !== undefined) {
        this.localClosureCalculations[this.editingCalculationIndex].values = this.includeArray.map((el) => {
          return {
            field: el,
            type: 'sum'
          }
        })
      } else {
        if(!this.newCalculation) {
          this.newCalculation = {
            text: this.calculationText,
            values: []
          }
        } else if(!this.newCalculation.values) {
          this.newCalculation.values = []
        }

        if(newValue) {
          this.newCalculation.values.push({
            type: 'sum',
            field: field
          })
        } else {
          this.newCalculation.values = this.newCalculation.values.filter(values => {
            return values.field != field
          })
        }
      }
    },
    handleSave() {
      if(this.editingCalculationIndex === undefined) {
        this.localClosureCalculations.push(this.newCalculation)
      }

      this.$settings.set('closureCalculations', cloneDeep(this.localClosureCalculations))
    },
    handleDelete() {
      if(this.editingCalculationIndex !== undefined) {
        this.localClosureCalculations = this.localClosureCalculations.filter((el, index) => index != this.editingCalculationIndex)
        this.editingCalculationIndex = undefined
        this.includeArray = []
        this.calculationText = undefined
        this.$settings.set('closureCalculations', cloneDeep(this.localClosureCalculations))
      } else {
        this.calculationText = ''
        this.newCalculation = {
          text: this.calculationText,
          values: []
        }
      }
    },
    handleSelect(index) {
      if(this.editingCalculationIndex === index) {
        this.editingCalculationIndex = undefined
        this.includeArray = []
        this.calculationText = undefined
      } else {
        this.editingCalculationIndex = index
        this.calculationText = this.localClosureCalculations[index].text
        this.includeArray = this.localClosureCalculations[index].values.map(el => el.field)
      }
    },
    print() {
      let id = 'cash_' + this.object.cashDeskId
      this.printLoading = true

      html2canvas(document.getElementById(id), { scale: 2 }).then((canvas) => {
        let a = document.createElement("a");
        if(!!this.object.cashDeskName)
          a.download = this.object.cashDeskName + ".png";
        else
          a.download = "Totale.png";

        a.href = canvas.toDataURL("image/png");
        a.click(); // MAY NOT ALWAYS WORK!
        this.printLoading = false
      }).catch((error)=>{
        console.error(error)
        this.printLoading = false
      });
    }
  },
  computed: {
    ghostColor() {
      return "#c34900"
    },
    ghostFields() {
      return [
        'billsGhostPaid',
        'customerCardLoadGhostPaid',
        'paymentCreditGhostPaid',
        'operatorPaymentDebitTotalGhost'
      ]
    },
    availableForPartialTotal() {
      if(!!this.object) {
        const mustNotBe = this.canViewGhost ? ['cashDeskId', 'cashDeskName'] : ['cashDeskId', 'cashDeskName', ...this.ghostFields]
        return Object.keys(this.object).filter(el => !mustNotBe.includes(el))
      } else {
        return []
      }
    },
    objectPresent() {
      return !!this.object
    },
    cash() {
      return this.objectPresent ? 
        this.sumCurrencyAmounts(
          this.object.billsCashPaid, 
          this.object.customerCardLoadCashPaid, 
          this.object.paymentCreditCashPaid,
          this.object.promoCashPaid,
          this.object.invoicesCashPaid
        ) : 0
    },
    cashInvoices() {
      return this.objectPresent ? 
        this.sumCurrencyAmounts(
          this.object.invoicesCashPaid, 
        ) : 0
    },
    cashPaymentCredit() {
      return this.objectPresent ? 
        this.sumCurrencyAmounts(
          this.object.paymentCreditCashPaid
        ) : 0
    },
    cashGhost() {
      return this.objectPresent ? 
        this.sumCurrencyAmounts(
          this.object.billsGhostPaid, 
          this.object.customerCardLoadGhostPaid, 
          this.object.paymentCreditGhostPaid,
          this.object.promoGhostPaid,
        ) : 0
    },
    cashGhostPaymentCredit() {
      return this.objectPresent ? 
        this.sumCurrencyAmounts(
          this.object.paymentCreditGhostPaid,
        ) : 0
    },
    cashDetail() {
      if(this.canViewGhost) {
        return this.sumCurrencyAmounts(
            this.cash,
            this.cashGhost
          )
      } else {
        return this.cash
      }
    },
    card() {
      return this.objectPresent ? 
        this.sumCurrencyAmounts(
          this.object.billsCardPaid, 
          this.object.customerCardLoadCardPaid, 
          this.object.paymentCreditCardPaid,
          this.object.promoCardPaid,
          this.object.invoicesCardPaid
        ) : 0
    },
    cardInvoices() {
      return this.objectPresent ? 
        this.sumCurrencyAmounts(
          this.object.invoicesCardPaid
        ) : 0
    },
    cardPaymentCredit() {
      return this.objectPresent ? 
        this.sumCurrencyAmounts(
          this.object.paymentCreditCardPaid
        ) : 0
    },
    check() {
      return this.objectPresent ? 
        this.sumCurrencyAmounts(
          this.object.billsCheckPaid, 
          this.object.customerCardLoadCheckPaid, 
          this.object.paymentCreditCheckPaid,
          this.object.promoCheckPaid,
          this.object.invoicesCheckPaid
        ) : 0
    },
    checkInvoices() {
      return this.objectPresent ? 
        this.sumCurrencyAmounts(
          this.object.invoicesCheckPaid
        ) : 0
    },
    checkPaymentCredit() {
      return this.objectPresent ? 
        this.sumCurrencyAmounts(
          this.object.paymentCreditCheckPaid
        ) : 0
    },
    otherPaid() {
      return this.objectPresent ? 
        this.sumCurrencyAmounts(
          this.object.billsOtherPaid,
          this.object.customerCardLoadOtherPaid,
          this.object.paymentCreditOtherPaid,
          this.object.promoOtherPaid,
          this.object.invoicesOtherPaid
        ) : 0
    },
    otherInvoices() {
      return this.objectPresent ? 
        this.sumCurrencyAmounts(
          this.object.invoicesOtherPaid
        ) : 0
    },
    otherPaymentCredit() {
      return this.objectPresent ? 
        this.sumCurrencyAmounts(
          this.object.paymentCreditOtherPaid
        ) : 0
    },
    partialTotalInStore() {
      return  this.sumCurrencyAmounts(
          this.cash,
          this.card,
          this.check,
          this.otherPaid
        )
    },
    partialTotalInvoices() {
      return  this.sumCurrencyAmounts(
          this.cashInvoices,
          this.cardInvoices,
          this.checkInvoices,
          this.otherInvoices
        )
    },
    partialTotalPaymentCredit() {
      return  this.sumCurrencyAmounts(
          this.cashPaymentCredit,
          this.cardPaymentCredit,
          this.checkPaymentCredit,
          this.otherPaymentCredit
        )
    },
    partialTotalGhost() {
      return this.cashGhost
    },
    partialTotalPaymentCreditGhost() {
      return this.cashGhostPaymentCredit
    },
    partialTotal() {
      if(this.canViewGhost) {
        return this.sumCurrencyAmounts(
          this.partialTotalInStore,
          this.partialTotalGhost
        )
      } else {
        return this.partialTotalInStore
      }
    },
    operatorPaymentDebitTotal() {
      return this.objectPresent ? 
        this.sumCurrencyAmounts(
          this.object.operatorPaymentDebitTotal
        ) : 0
    },
    operatorPaymentDebitTotalGhost() {
      return this.objectPresent ? 
        this.sumCurrencyAmounts(
          this.object.operatorPaymentDebitTotalGhost
        ) : 0
    },
    operatorPaymentDebitDetail() {
      if(this.canViewGhost) {
        return this.sumCurrencyAmounts(
          this.operatorPaymentDebitTotal,
          this.operatorPaymentDebitTotalGhost
        )
      } else {
        return this.operatorPaymentDebitTotal
      }
    },
    paymentDebitTotal() {
      return this.objectPresent ? 
        this.sumCurrencyAmounts(
          this.object.paymentDebitTotal
        ) : 0
    },
    partialPaymentDebitTotal() {
      return this.sumCurrencyAmounts(
          this.paymentDebitTotal,
          this.operatorPaymentDebitTotal
        )
    },
    partialPaymentDebitTotalGhost() {
      return this.operatorPaymentDebitTotalGhost
    },
    partialPaymentDebitTotalPlus() { 
      if(this.canViewGhost) {
        return this.sumCurrencyAmounts(
          this.partialPaymentDebitTotal,
          this.partialPaymentDebitTotalGhost
        )
      } else {
        return this.sumCurrencyAmounts(
          this.partialPaymentDebitTotal,
        )
      }
    },
    bankCash() {
      return this.sumCurrencyAmounts(
        this.cash,
        - this.partialPaymentDebitTotal,
      )
    },
    bankCashGhost() {
      return this.sumCurrencyAmounts(
        this.cashGhost,
        - this.partialPaymentDebitTotalGhost,
      )
    },
    bankTotal() {
      if(this.canViewGhost) {
        return this.sumCurrencyAmounts(
          this.bankCash,
          this.bankCashGhost
        )
      } else {
        return this.bankCash
      }
    },
    generalTotal() {
      return this.sumCurrencyAmounts(
        this.partialTotalInStore,
        - this.partialPaymentDebitTotal
      )
    },
    generalTotalPlus() {
      if(this.canViewGhost) {
        return this.sumCurrencyAmounts(
          this.partialTotal,
          - this.partialPaymentDebitTotalPlus
        )
      } else {
        return this.sumCurrencyAmounts(
          this.generalTotal
        )
      }
    }
  },
  settings: {
    closureCalculations: {
      initial(value) {
        if(!value) this.localClosureCalculations = []
        else this.localClosureCalculations = cloneDeep(value)
      },
      change(value) {
        if(!value) this.localClosureCalculations = []
        else this.localClosureCalculations = cloneDeep(value)
      },
    }
  }
}
</script>

<style>

.v-input--checkbox.little-checkbox .v-label {
  font-size: 10pt;
  color: inherit !important;
}

.table-of-value {
  border-spacing: 0px;
}

.table-of-value .value {
  text-align: end;
  padding-right: 5px;
}

.table-of-value .table-header {
  padding-left: 15px;
  padding-right: 5px;
  padding-top: 5px;
  max-width: 140px;
  width: auto;
  text-align: right;
  vertical-align: top;
  line-height: 15px;
  font-weight: 800 !important;
  color: rgba(0, 0, 0, 0.8);
}

.table-of-value .descriptor {
  padding-right: 10px;
}

.table-of-value .top-border {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.table-of-value .right-border {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.table-of-value td, .table-of-value th {
  width: auto;
}

</style>