import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import DateHandler from "@/services/common/dateHandler";

class ClosuresService {
  close(from, to, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/payments/closures/close", {
          from: from,
          to: to,
          filters: filters
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results)
          }
        }).catch((err) => {
          reject(err);
        });
    });
  }

  dailyClosures(from, to) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/payments/closures/dailyClosures", {
          from: from,
          to: to,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results)
          }
        }).catch((err) => {
          reject(err);
        });
    });
  }

  closeDetails(from, to, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/payments/closures/closureDetails", {
          from: from,
          to: to,
          filters: filters
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results)
          }
        }).catch((err) => {
          reject(err);
        });
    });
  }


  _fieldsToExport() {
    return {
      "Data": {
        field: "date",
        callback: (value) => {
          if (new Date(value).toString() !== "Invalid Date")
            return DateHandler._buildDateToExport(`${value}`);
          else
            return value;
        },
      },
      "Totale": {
        field: "total",
        callback: (value) => {
          return `${Number(value).toFixed(2).replace('.', ',')}`
        },
      },
      "Parrucchiere Imponibile": {
        field: "parImp",
        callback: (value) => {
          return `${Number(value).toFixed(2).replace('.', ',')}`
        },
      },
      "Parrucchiere Iva": {
        field: "parIVA",
        callback: (value) => {
          return `${Number(value).toFixed(2).replace('.', ',')}`
        },
      },
      "Vendita Imponibile": {
        field: "venImp",
        callback: (value) => {
          return `${Number(value).toFixed(2).replace('.', ',')}`
        },
      },
      "Vendita Iva": {
        field: "venIVA",
        callback: (value) => {
          return `${Number(value).toFixed(2).replace('.', ',')}`
        },
      },
      "Estetica Imponibile": {
        field: "estImp",
        callback: (value) => {
          return `${Number(value).toFixed(2).replace('.', ',')}`
        },
      },
      "Estetica Iva": {
        field: "estIVA",
        callback: (value) => {
          return `${Number(value).toFixed(2).replace('.', ',')}`
        },
      },
      "Bar Imponibile": {
        field: "barImp",
        callback: (value) => {
          return `${Number(value).toFixed(2).replace('.', ',')}`
        },
      },
      "Bar Iva": {
        field: "barIVA",
        callback: (value) => {
          return `${Number(value).toFixed(2).replace('.', ',')}`
        },
      },
      "Bar Vendita Imponibile": {
        field: "barVenImp",
        callback: (value) => {
          return `${Number(value).toFixed(2).replace('.', ',')}`
        },
      },
      "Bar Vendita Iva": {
        field: "barVenIVA",
        callback: (value) => {
          return `${Number(value).toFixed(2).replace('.', ',')}`
        },
      },
      "Altro Imponibile": {
        field: "otherImp",
        callback: (value) => {
          return `${Number(value).toFixed(2).replace('.', ',')}`
        },
      },
      "Altro Iva": {
        field: "otherIVA",
        callback: (value) => {
          return `${Number(value).toFixed(2).replace('.', ',')}`
        },
      },
    }
  }
}

export default new ClosuresService();
