<template>
  <div>
      <div 
        v-if="filterVisible" 
        style="min-height: 100px"
        class="d-flex align-center px-2"
      >
        <div style="width: 200px">
          <DatePicker 
            v-model="localStartDate"
            filled
            dense
            hide-details="auto"
            label="Inizio"
            :loading="loading"
            @input="$emit('update:start-date', $event)"
          ></DatePicker>
        </div>
        <div style="width: 200px" class="ml-3">
          <DatePicker 
            v-model="localEndDate"
            filled
            dense
            hide-details="auto"
            label="Fine"
            :loading="loading"
            @input="$emit('update:end-date', $event)"
          ></DatePicker>
        </div>
        <v-chip 
          color="primary"
          class="ml-7"
          @click="setToday"
          :disabled="loading"
        >Oggi</v-chip>
        <v-chip 
          color="primary"
          class="ml-1"
          @click="setYesterday"
          :disabled="loading"
        >Ieri</v-chip>
        <v-chip
          class="ml-1"
          color="primary"
          @click="setThisMonth"
          :disabled="loading"
        >Questo mese</v-chip>
        <v-chip
          class="ml-1"
          color="primary"
          @click="setLastMonth"
          :disabled="loading"
        >Lo scorso mese</v-chip>
        <v-chip 
          class="ml-1"
          color="primary"
          @click="setThisYear"
          :disabled="loading"
        >Quest'anno</v-chip>
        <v-autocomplete
          v-model="localWithImports"
          :items="[
            {text: 'In contanti', value: 'cash'},
            {text: 'Elettronico', value: 'card'},
            {text: 'Assegni', value: 'check'},
            {text: 'Altri metodi', value: 'other'},
            {text: 'Non pagato', value: 'notPaid'},
          ]"
          class="ml-2"
          hide-details="auto"
          style="max-width: 300px"
          multiple
          small-chips
          deletable-chips
          dense
          filled
          chips
          label="Con importo"
          @input="$emit('update:with-imports', $event)"
        ></v-autocomplete>
        <v-autocomplete
          v-model="localType"
          :items="[
            {text: 'Scontrino', value: 'receipt'},
            {text: 'Spese per contanti', value: 'operatorPaymentDebit'},
            {text: 'Storni', value: 'paymentDebit'},
          ]"
          class="ml-2"
          hide-details="auto"
          style="max-width: 300px"
          multiple
          small-chips
          deletable-chips
          dense
          filled
          chips
          label="Tipo"
          @input="$emit('update:type', $event)"
        ></v-autocomplete>
        <v-spacer></v-spacer>
        <v-btn
          class="mr-2"
          text
          @click="loadPaymentTransactions"
          :loading="loading"
        >Calcola</v-btn>
      </div>
    <v-divider></v-divider>
    <TypeDataTable
      v-model="localValue"
      :loading="loading"
      loading-text="Caricamento storico ..."
      :headers="headers"
      :items="filteredPaymentTransactions"
      :translator="translator"
      :height="dataTableHeight"
      :show-actions="false"
      @input="$emit('input', $event)"
      :default-data-table-header-select="false"
      disable-pagination
      :show-select="showSelect"
    >

      <template v-slot:header.data-table-select>
        <v-btn
          icon
          small
          @click="toggleFilter"
        >
          <v-icon 
            small
            :color="filterVisible ? 'primary' : 'default'"
          >mdi-filter</v-icon>
        </v-btn>
      </template>
      <template  v-slot:custom-customer="{ item }">
        <div v-if="item.billType === 'service' && item.info.customers">
          {{item.info.customers.map(customer => !!customer.firstname && !!customer.lastname ? `${customer.firstname} ${customer.lastname}` : undefined).filter(el => !!el).join(',')}}
        </div>
        <div v-else-if="!!item.bill && item.bill.type==='item'">
          {{item.info.reservation.reservation.reservationName}}
        </div>
        <div v-else-if="item.type == 'customer' && !!item.customer">
          {{item.customer.firstname}} {{item.customer.lastname}}
        </div>
        <div v-else-if="item.type == 'bill' && !!item.bills && item.bills.length > 0 && !!item.bills[0].billCustomers && item.bills[0].billCustomers.length > 0 && !!item.bills[0].billCustomers[0].customer">
          {{item.bills[0].billCustomers[0].customer.firstname}} {{item.bills[0].billCustomers[0].customer.lastname}}
        </div>
        <div v-else-if="item.type == 'bill' && !item.customer">
          Cliente Dal Bar
        </div>
      </template>

      <template v-slot:custom-closedAt="{ item }">
        <DateTimeFormattor
          :value="new Date(item.info.closedAt)"
        ></DateTimeFormattor>
      </template>
      <template v-slot:custom-totalValue="{ item }">
        {{calculateTotal(item)}}
      </template>
      <template v-slot:custom-providedServices="{ item }">
        <div
          v-html="calculateProvidedServices(item)"
        ></div>
      </template>
    </TypeDataTable>
  </div>
</template>

<script>
import paymentTransactionsService from '@/services/paymentTransactions/paymentTransactions.service.js'
import paymentDebitService from '@/services/paymentDebits/paymentDebits.service.js'
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import DateTimeFormattor from '../../../common/DateTimeFormattor.vue'
import DatePicker from '@/components/common/DatePickerCommon.vue'
import { orderBy } from 'lodash'

export default {
  name: "CashDeskTransactionTable",
  components: {
    TypeDataTable,
    DateTimeFormattor,
    DatePicker
  },
  data: function() {

    return {
      paymentTransactions: [],
      localValue: undefined,
      loading: false,
      filterVisible: true,
      headers: [],

      localStartDate: undefined,
      localEndDate: undefined,
      localWithImports: [],
      localType: [],
    }
  },
  mounted: function() {
    this.localStartDate = this.startDate
    this.localEndDate = this.endDate
    this.localWithImports = this.withImports
    this.localType = this.type

    this.loadHeaders()
    this.loadPaymentTransactions()
  },
  props: {
    height: {
    },
    value: {
    },
    startDate: {
    },
    endDate: {
    },
    type: {
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    withImports: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  methods: {
    loadPaymentTransactions() {
      this.loading = true
      Promise.all([
        paymentTransactionsService.history(1, 400, {
          startDate: this.localStartDate,
          endDate: this.localEndDate,
          withImports: this.localWithImports
        }),
        paymentDebitService.list(1, 400, {

        })
      ]).then((results) => {
        let finalRows = [ ]

        if(!this.localType ||
            this.localType.length == 0 ||
            (!!this.localType && this.localType.length > 0 && this.localType.includes('receipt'))
        ) {
          finalRows = [
            ...finalRows,
            ...results[0].rows
          ]
        }

        let newResults = []
        if(
            !this.localWithImports || 
            this.localWithImports.length == 0 || 
            (!!this.localWithImports && this.localWithImports.length > 0 && this.localWithImports.includes('cash'))
          ) {

          const paymentDebitSelected = !this.localType ||
            this.localType.length == 0 ||
            (!!this.localType && this.localType.length > 0 && this.localType.includes('paymentDebit'))
          const operatorPaymentDebitSelected = !this.localType ||
            this.localType.length == 0 ||
            (!!this.localType && this.localType.length > 0 && this.localType.includes('operatorPaymentDebit'))

          for(let i = 0; i < results[1].rows.length; i += 1 || operatorPaymentDebitSelected) {
            if(results[1].rows[i].type == 'operator' && !operatorPaymentDebitSelected) {
              continue
            } else if((results[1].rows[i].type == 'customer' || results[1].rows[i].type == 'bill') && !paymentDebitSelected) {
              continue
            }

            newResults.push({
              id: 'pd_' + results[1].rows[i].id,
              customer: results[1].rows[i].customer,
              operator: results[1].rows[i].operator,
              cashPaid: results[1].rows[i].amount,
              checkPaid: results[1].rows[i].checkPaid,
              cardPaid: results[1].rows[i].cardPaid,
              otherPaid: results[1].rows[i].otherPaid,
              notPaid: results[1].rows[i].notPaid,
              info: {
                closedAt: results[1].rows[i].openedAt
              }
            })
          }

          finalRows = [
            ...finalRows,
            ...newResults  
          ]
        }

        this.paymentTransactions = orderBy(finalRows, (el) => new Date(el.info.closedAt)).reverse()
          
        this.loading = false
      })
    },
    loadHeaders() {
      paymentTransactionsService.fields().then((headers) => {
        this.headers = headers
      })
    },
    translator(key, value) {
      const documentTypeMapping = {
        'receipt': 'Ricevuta',
        'invoice': 'Fattura',
        'payLater': 'Paga successivamente'
      }

      if(key == 'documentType') {
        return documentTypeMapping[value]
      }
    },
    setToday() {
      this.localStartDate = new Date()
      this.localEndDate = new Date()
    },
    setYesterday() {
      const yesterday = new Date(new Date().getTime() - 24 * 60 * 60000)
      this.localStartDate = yesterday
      this.localEndDate = yesterday
    },
    setThisMonth() {
      var today = new Date();
      var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
      firstDay.setHours(0)
      firstDay.setMinutes(0)
      this.localStartDate = firstDay

      var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      lastDay.setHours(0)
      lastDay.setMinutes(0)
      this.localEndDate = lastDay
    },
    setLastMonth() {
      var today = new Date();
      var firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      firstDay.setHours(0)
      firstDay.setMinutes(0)
      this.localStartDate = firstDay

      var lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
      lastDay.setHours(0)
      lastDay.setMinutes(0)
      this.localEndDate = lastDay
    },
    setThisYear() {
      var today = new Date();
      var firstDay = new Date(today.getFullYear(), 0, 1);
      firstDay.setHours(0)
      firstDay.setMinutes(0)
      this.localStartDate = firstDay

      var lastDay = new Date(today.getFullYear() + 1, 0, 0);
      lastDay.setHours(0)
      lastDay.setMinutes(0)
      this.localEndDate = lastDay
    },
    calculateTotal(item) {
      let totalValue = this.sumCurrencyAmounts(
        item.cashPaid,
        item.checkPaid,
        item.cardPaid,
        item.otherPaid,
        item.notPaid
      )
      return `${totalValue} €`
    },
    sumCurrencyAmounts(...amounts) {
      if(!amounts) {
        return 0
      } else if(amounts.length == 0) {
        return 0
      } else {
        let accumulator = 0
        for(let i = 0; i < amounts.length; i += 1) {
          if(!!amounts[i]) {
            accumulator = Number(Number(Number(Number(accumulator).toFixed(2)) + Number(Number(amounts[i]).toFixed(2))).toFixed(2))
          }
        }
        return accumulator
      }
    },
    calculateProvidedServices(item) {
      let services = ''
      if(!!item.info && !!item.info.items && item.info.items.length > 0) {
        services = item.info.items.map(el => {
          let final = ''
          if(!!el.name) {
            final += el.name
          }

          if(!!el.quantity) {
            final += ', qt. ' + el.quantity
          }

          if(!!el.operators) {
            final += ' (' + el.operators.map(op => op.firstname + ' ' + op.lastname ).join(', ') + ')'
          }
          return final
        }).filter(el => !!el).join('<br />')
      } else if(!!item && !!item.bill && item.bill.type==='item') {
        services = 'Prestazioni ristorante'
      } else {
        services = 'Nessuna prestazione'
      }
      return services
    },
    toggleFilter() {
      this.filterVisible = !this.filterVisible
    }
  },
  computed: {
    filteredPaymentTransactions() {
      return this.paymentTransactions
    },
    dataTableHeight() {
      if(this.filterVisible) {
        return 'calc(' + this.height + ' - 100px)'
      } else {
        return this.height
      }
    }
  },
  watch: {
    startDate(newVal) {
      this.localStartDate = newVal
    },
    endDate(newVal) {
      this.localEndDate = newVal
    },
    withImports(newVal) {
      this.localWithImports = newVal
    },
    type(newVal) {
      this.localType = newVal
    }
  }
}
</script>

<style>

</style>